<div class="container">
    <h3 class="h3">shopping Demo-8 </h3>
    <div class="row">
        <div class="col-md-4 col-lg-3 col-sm-6">
            <div class="product-item">
                <div class="product-image">
                    <a href="#">
                        <img class="pic-1" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-1.jpg">
                        <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-2.jpg">
                    </a>
                    <ul class="social">
                        <li><a href="" class="fa fa-search"></a></li>
                        <li><a href="" class="fa fa-shopping-bag"></a></li>
                        <li><a href="" class="fa fa-shopping-cart"></a></li>
                    </ul>
                    <span class="product-discount-label">-20%</span>
                </div>
                <div class="product-content">
                    <span class="title">
                        <a href="#">Rochie de seara lunga din dantela neagra cu franjuri si corset reglabil pe mai multe marimi</a>
                    </span>
                    <div>
                        <b class="presented">Presented in : </b>
                        <a class="fw" href="#">Miami</a> <b class="presented"> & </b> <a class="fw" href="#">Athens</a>
                    </div>
                    <div class="price">$ 8.00
                        <span>$ 10.00</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-6">
            <div class="product-item">
                <div class="product-image">
                    <a href="#">
                        <img class="pic-1" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-3.jpg">
                        <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-4.jpg">
                    </a>
                    <ul class="social">
                        <li><a href="" class="fa fa-search"></a></li>
                        <li><a href="" class="fa fa-shopping-bag"></a></li>
                        <li><a href="" class="fa fa-shopping-cart"></a></li>
                    </ul>
                    <span class="product-discount-label">-30%</span>
                </div>
                <div class="product-content">
                    <span class="title">
                        <a href="#">Rochie de seara lunga, tip sirena, din tulle cu aplicatii din dantela neagra florala.</a>
                    </span>
                    <div class="price">$ 7.00
                        <span>$ 10.00</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-6">
            <div class="product-item">
                <div class="product-image">
                    <a href="#">
                        <img class="pic-1" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-5.jpg">
                        <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-6.jpg">
                    </a>
                    <ul class="social">
                        <li><a href="" class="fa fa-search"></a></li>
                        <li><a href="" class="fa fa-shopping-bag"></a></li>
                        <li><a href="" class="fa fa-shopping-cart"></a></li>
                    </ul>
                    <span class="product-discount-label">-20%</span>
                </div>
                <div class="product-content">
                     <span class="title">
                        <a href="#">Rochie scurta de seara din dantela roz cu franjuri</a>
                    </span>
                    <div class="price">$ 8.00
                        <span>$ 10.00</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-lg-3 col-sm-6">
            <div class="product-item">
                <div class="product-image">
                    <a href="#">
                        <img class="pic-1" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-5.jpg">
                        <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-6.jpg">
                    </a>
                    <ul class="social">
                        <li><a href="" class="fa fa-search"></a></li>
                        <li><a href="" class="fa fa-shopping-bag"></a></li>
                        <li><a href="" class="fa fa-shopping-cart"></a></li>
                    </ul>
                    <span class="product-discount-label">-20%</span>
                </div>
                <div class="product-content">
                     <span class="title">
                        <a href="#">Rochie scurta de seara din dantela roz cu franjuri</a>
                    </span>
                    <div class="price">$ 8.00
                        <span>$ 10.00</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-lg-3 col-sm-6">
            <div class="product-item">
                <div class="product-image">
                    <a href="#">
                        <img class="pic-1" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-5.jpg">
                        <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-6.jpg">
                    </a>
                    <ul class="social">
                        <li><a href="" class="fa fa-search"></a></li>
                        <li><a href="" class="fa fa-shopping-bag"></a></li>
                        <li><a href="" class="fa fa-shopping-cart"></a></li>
                    </ul>
                    <span class="product-discount-label">-20%</span>
                </div>
                <div class="product-content">
                     <span class="title">
                        <a href="#">a</a>
                    </span>
                    <div class="price">$ 8.00
                        <span>$ 10.00</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-lg-3 col-sm-6">
            <div class="product-item">
                <div class="product-image">
                    <a href="#">
                        <img class="pic-1" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-5.jpg">
                        <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-6.jpg">
                    </a>
                    <ul class="social">
                        <li><a href="" class="fa fa-search"></a></li>
                        <li><a href="" class="fa fa-shopping-bag"></a></li>
                        <li><a href="" class="fa fa-shopping-cart"></a></li>
                    </ul>
                    <span class="product-discount-label">-20%</span>
                </div>
                <div class="product-content">
                     <span class="title">
                        <a href="#">b</a>
                    </span>
                    <div class="price">$ 8.00
                        <span>$ 10.00</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-lg-3 col-sm-6">
            <div class="product-item">
                <div class="product-image">
                    <a href="#">
                        <img class="pic-1" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-5.jpg">
                        <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo7/images/img-6.jpg">
                    </a>
                    <ul class="social">
                        <li><a href="" class="fa fa-search"></a></li>
                        <li><a href="" class="fa fa-shopping-bag"></a></li>
                        <li><a href="" class="fa fa-shopping-cart"></a></li>
                    </ul>
                    <span class="product-discount-label">-20%</span>
                </div>
                <div class="product-content">
                     <span class="title">
                        <a href="#">c</a>
                    </span>
                    <div class="price">$ 8.00
                        <span>$ 10.00</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>