<div id="aboutview" class="container">
    <div class="row">
        <div class="col-12">
            <div class="jumbotron jumbotron-fluid" style="background-color:#222222">
                <div class="main text-white">
                    <h1 class="display-4 animate__animated animate__bounce">{{aboutDesigner}}</h1>
                    <p class="lead"><span class = "logo1 animate__animated animate__bounceInLeft" > Herminas Reea </span> is a specialized brand in Haute Couture dresses.</p>
                    <hr class="my-4">
                    <p class="text-justify">{{about1}}</p>
                    <!--<p class="text-justify">{{about2}}</p>-->
                    <p class="text-justify">{{about3c}}</p>
                    <p class="text-justify">Herminas Reea has been present to the most important fashion weeks in U.S.A, Costa Rica, Greece, Malta &amp; Romania. <br> <a class="menufocus" href="https://www.MiamiFashionWeek.com" target="_blank" matTooltip="“MIAFW IS THE SECOND MOST RELEVANT FASHION EVENT IN THE USA” (Source - Nielsen, March 2018)"> Miami Fashion Week</a> IS THE SECOND MOST RELEVANT FASHION EVENT IN THE USA" (Source - Nielsen, March 2018)</p>
                    <p class="text-justify">{{about3b}}</p>
                    <hr class="my-4">
                    <table class="table table-dark table-hover">
                        <thead>
                            <tr>
                                <th>{{fashionWeekHeader}}</th>
                                <th>{{collectionHeader}}</th>
                                <th>{{yearHeader}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let collection of collections">
                                <td><code><p class="text-justify show-btn" (click)="onClick(collection.videoId);openModal(content)" style="cursor: pointer">{{collection.fashionWeek}}</p></code></td>
                                <td><p class="text-justify">{{collection.name}}</p></td>
                                <td><p class="text-justify">{{collection.year}}</p></td>
                            </tr>
                        </tbody>
                    </table>
                    <p id="about5" class="text-justify"> </p>
                    <p id="about6" class="text-justify"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div style="position: relative;">
        <!--Close button-->
      <div style="position: absolute; top: 10px; right: 10px;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--content-->
      <div class="embed-responsive embed-responsive-16by9">
        <youtube-player 
          [videoId]="videoId" 
          suggestedQuality="highres"
          width="960" 
          height="540">
        </youtube-player>
      </div> 
      <!--footer-->
        <div class="modal-footer">
          <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        </div>
    </div>
</ng-template>

<!-- Footer -->
<footer class="footer1 py-5 footer">
    <div class="container">
      <p class="m-0 text-center text-white">Copyright &copy; Herminas Reea 2022</p>
    </div>
  </footer>