import { Component, HostListener, OnInit } from '@angular/core';
import * as menuData from './../app-menu/menu.json';
import {CommonModule} from "@angular/common"
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent implements OnInit {
  public isCollapsed = false;
  menuItems: MenuOption[] = (menuData as any).default;

  constructor() {
    console.log(this.menuItems);
   }
  ngOnInit() {}

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if(window.innerWidth<768){
      this.isCollapsed=false;
    }
  }
}

export interface MenuOption {
  name:string;
  subItems: [];
  isSimple:boolean;
  routeLink:string;
}
