import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { SwiperOptions } from 'swiper';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ImageUtil, ImagesData, Images } from './ImagesUtil';
import { HostListener } from "@angular/core";
import { SwiperComponent } from 'ngx-useful-swiper';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  closeResult: string;
  modalOption: NgbModalOptions = {};
  urlSafe: SafeResourceUrl;
  slideType: string;
  initialSlide: number= 0;
  currentImageArray: ImagesData;
  slides1: SlideItem[] = [];
  slides2: SlideItem[] = [];
  scrHeight: any;
  scrWidth: any;
  imageUtils: ImageUtil;
  config: SwiperOptions = {
    autoHeight: true,
    allowTouchMove: true,
    watchSlidesProgress:true,
    breakpoints: {
      1024: {
        slidesPerView: 1
      },
      500: {
        slidesPerView: 1
      },
      400: {
        slidesPerView: 1
      },
      300: {
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: false,
    effect: 'fade',
    grabCursor: true,
    fadeEffect: {
      crossFade: true
    }
  }

  @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;

  /**
   * Constructor
   */
  constructor(private route: ActivatedRoute, public el: ElementRef) {
    this.getScreenSize();
    this.imageUtils = new ImageUtil();
    this.route.params.subscribe(params => this.updateSliderView(params.collection));
  }

  ngOnInit() { }

  /**
   * Function purpose: populate current image array
   * @param gal : current 
   */
  updateSliderView(gal: string) {
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute("style", "background-color: #222222");
    const childElements = this.el.nativeElement.childNodes;
    console.log(childElements);
    this.currentImageArray = new ImagesData();
    console.log("updateSliderView clicked -> " + gal);
    this.initialSlide = 0;

    switch (gal) {
      case '2019_TheBirds': {
        this.currentImageArray = this.imageUtils.theBirds;
        break;
      }
      case '2017_TheGoods': {
        this.currentImageArray = this.imageUtils.theGoods;
        break;
      }
      case '2017_TheGoods_Buc': {
        this.currentImageArray = this.imageUtils.theGoods_Buc;
        break;
      }
      case '2017_TheGoods_Malta': {
        this.currentImageArray = this.imageUtils.theGoods_Malta;
        break;
      }
      case '2018_ButterflyEffect': {
        this.currentImageArray = this.imageUtils.butterflyEffect;
        break;
      }
      case '2018_ButterflyEffect_AXDW': {
        this.currentImageArray = this.imageUtils.butterflyEffect_AXDW;
        break;
      }
      case '2018_ButterflyEffect_Malta': {
        this.currentImageArray = this.imageUtils.butterflyEffect_Malta;
        break;
      }
      case '2019_TheBirds_MIAFW': {
        this.currentImageArray = this.imageUtils.theBirds_MIAFW;
        break;
      }
      case '2019_TheBirds_AXDW': {
        this.currentImageArray = this.imageUtils.theBirds_AXDW;
        break;
      }
      case '2019_TheBirds': {
        this.currentImageArray = this.imageUtils.theBirds;
        break;
      }
      case 'bridal': {
        this.currentImageArray = this.imageUtils.bridal;
        break;
      }
      case 'men_shirt': {
        this.currentImageArray = this.imageUtils.men_shirt;
        break;
      }
      case 'men_coats': {
        this.currentImageArray = this.imageUtils.men_coats;
        break;
      }
      case 'bjeans': {
        this.currentImageArray = this.imageUtils.bjeans;
        break;
      }
      case 'men_long_coats': {
        this.currentImageArray = this.imageUtils.men_long_coats;
        break;
      }
      case 'partners': {
        this.currentImageArray = this.imageUtils.partners;
        break;
      }
      case 'kids': {
        this.currentImageArray = this.imageUtils.kids;
        break;
      }
      case 'bsuits': {
        this.currentImageArray = this.imageUtils.bsuits;
        break;
      }
      case 'initSlider': {
        this.currentImageArray = this.imageUtils.initSlider;
        break;
      }
      case 'LostInTheCity_CRFW': {
        this.currentImageArray = this.imageUtils.lostInTheCity_CRFW;
        break;
      }
      case 'LostInTheCity': {
        this.currentImageArray = this.imageUtils.lostInTheCity;
        break;
      }
      default: {
        this.currentImageArray = this.imageUtils.initSlider;
        break;
      }
    }
    this.populateSlides();

  }

  /**
   * Function purpose: populate current slide
   */
  populateSlides() {
    console.log("populateSlides -> ");
    //this.slides1.length = 0;
    this.slides1= [];
    let imgArray: Images[] = this.currentImageArray.images;
    for (let i = 0; i < imgArray.length; i++) {
      //current slide
      let currentSlide: SlideItem = new SlideItem();
      let img: Images = imgArray[i++];
      let img1 = img.url;
      let isSimple = img.simple;
      let slides = img.slides;
      //set first image in slide
      currentSlide.img1 = img1;

      //check if slide is simple
      if (isSimple === true) {
        --i;
      }

      //check for second image in slide
      let img2 = null;
      if (i < imgArray.length && !isSimple) {
        if ((slides == 3) || (slides == 4))
          img2 = imgArray[i++].url;
        else
          img2 = imgArray[i].url;
      }
      //set the second image in slide
      currentSlide.img2 = img2;

      //check for the third image in slide
      let img3 = null;
      if (i < imgArray.length && !isSimple && (slides === 3)) {
        img3 = imgArray[i].url;
      } else if (i < imgArray.length && !isSimple && (slides === 4)){
        img3 = imgArray[i++].url;
      }

      //set the third image in slide
      currentSlide.img3 = img3;

       //check for the forth image in slide
       let img4 = null;
       if (i < imgArray.length && !isSimple && (slides === 4)) {
         img4 = imgArray[i].url;
       }

       //set the third image in slide
      currentSlide.img4 = img4;

      this.slides1.push(currentSlide);
    }
    for (let i = 0; i < this.slides1.length; i++) {
      let img1= null;
      let img2= null;
      let img3= null;
      let img4= null;
      img1= this.slides1[i].img1;
      img2= this.slides1[i].img2;
      img3= this.slides1[i].img3;
      img4= this.slides1[i].img4;
      
      console.log("#####################");
      console.log("slides: "+ i);
      if(img1!=null){
        console.log("img1: "+ img1);
      }
      if(img2!=null){
        console.log("img2: "+ img2);
      }
      if(img3!=null){
        console.log("img3: "+ img3);
      }
      if(img4!=null){
        console.log("img4: "+ img4);
      }

    }
    //this.usefulSwiper.swiper.slideTo(1,this.currentImageArray.images.length-1);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
  }

  /**
   * 
   * @param img 
   */
  show(img: string): boolean {
    if (img != null && img.length > 0)
      return true;
    else
      return false;
  }
}

export class SlideItem {
  img1: string;
  img2: string;
  img3: string;
  img4: string;
}

