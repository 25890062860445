import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
//import Client from 'shopify-buy';
import { Product } from './models/product.model';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper';

// Initializing a client to return content in the store's primary language
/*const client = Client.buildClient({
  domain: 'herminas-reea.myshopify.com',
  storefrontAccessToken: '6d7e7db1653e9272851000debb42d82c'
});*/

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  html: string;
  products: Product[]
  fetchType: '’Collection’ or ‘Product’';
  fetchId: 'collection or product ID';
  myThumbnail = "https://herminasreea.com/images/a2019TheBirds/10.jpg";
  myFullresImage = "https://herminasreea.com/images/a2019TheBirds/10.jpg";
  scrHeight: any;
  scrWidth: any;
  slides: String[] = ["images/a2019TheBirds/1.jpg", "images/a2019TheBirds/2.jpg"];
  config: SwiperOptions = {
    autoHeight: true,
    allowTouchMove: true,
    breakpoints: {
      1024: {
        slidesPerView: 1
      },
      500: {
        slidesPerView: 1
      },
      400: {
        slidesPerView: 1
      },
      300: {
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: false,
    effect: 'fade',
    grabCursor: true,
    fadeEffect: {
      crossFade: true
    }
  }
  constructor(private router: Router) {
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute("style", "background-color:#ffffff");
  }

  ngOnInit(): void {
    this.router.navigateByUrl("https://google.com").then((bool) => {}).catch()
    // Fetch a single product by ID
    //const productId = '4421416583241';

    /*client.product.fetch(productId).then((product) => {
      // Do something with the product
      console.log(product);
      this.html =
        "<img class='product__image' src='" + product.selectedVariantImage.src + "' >" +
        "<h2 class='product__title'>" + product.title + "</h2>" +
        "<a class='product__buy' href='" +
        product.selectedVariant.checkoutUrl(1) +
        "'>Buy Now!</a>";


    });*/

    // Fetch all products in your shop
    /*client.product.fetchAll().then((products) => {
      // Do something with the products
      console.log(products);
      this.products = products;
      let product: Product = this.products[0];
      console.log(product.id);
      console.log(product.images[0].src);
      //console.log(JSON.stringify(products));
    });


    client.product.fetch('Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ0MjE0MTY1ODMyNDE=').then((product) => {
      // Do something with the product
      console.log("product checkout" + product);
    });*/




    /*client.fetchProduct('your-product-id').then(function (product) {
  
      var html =
        "<img class='product__image' src='" + product.selectedVariantImage.src + "' >" +
        "<h2 class='product__title'>" + product.title + "</h2>" +
        "<a class='product__buy' href='" +
        product.selectedVariant.checkoutUrl(1) +
        "'>Buy Now!</a>";
  
      $('#product-1').html(html);
  
    });*/

  }


  checkout() {
    let url = "https://herminas-reea.myshopify.com/cart/31365861769289:1?channel=buy_button";
    window.location.href = url;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
  }

}
