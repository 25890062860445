import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-smallslider',
  templateUrl: './smallslider.component.html',
  styleUrls: ['./smallslider.component.css']
})
export class SmallsliderComponent implements OnInit {
  closeResult: string;
  modalOption: NgbModalOptions = {};
  urlSafe: SafeResourceUrl;
  imgUrl: String;
  config1: SwiperOptions = {
    autoHeight: true,
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    breakpoints: {
      1200: {
        slidesPerView: 5,
        spaceBetween: 20

      },
      992: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      576: {
        slidesPerView: 3,
        spaceBetween: 25
      },
      300: {
        slidesPerView: 2,
        spaceBetween: 25
      }
    }
  }

  images = [
    "images/a2019mfw/1.jpg",
    "images/a2019mfw/2.jpg",
    "images/a2019mfw/3.jpg",
    "images/a2019mfw/4.jpg",
    "images/a2019mfw/5.jpg",
    "images/a2019mfw/6.jpg",
    "images/a2019mfw/7.jpg",
    "images/a2019mfw/8.jpg",
    "images/a2019mfw/9.jpg",
    "images/a2019mfw/10.jpg",
    "images/a2019mfw/11.jpg",
    "images/a2019mfw/12.jpg",
    "images/a2019mfw/13.jpg",
    "images/a2019mfw/14.jpg",
    "images/a2019mfw/15.jpg",
    "images/a2019mfw/16.jpg",
    "images/a2019mfw/17.jpg",
    "images/a2019mfw/18.jpg",
    "images/a2019mfw/19.jpg",
    "images/a2019mfw/20.jpg",
    "images/a2019mfw/21.jpg",
    "images/a2019mfw/22a.jpg",
    "images/a2019mfw/22b.jpg",
    "images/a2019mfw/23.jpg"
  ];

  /**
   * Constructor
   */
  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) { }

  ngOnInit(): void { }

  /**
   * Function purpose: open modal
   * @param content
   */
  openModal(content) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Kk3xpdBAoBo');
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    this.modalService.open(content, this.modalOption);
  }

  /**
   * Function purpose : set image url
   * @param event 
   */
  setUrl(event) {
    this.imgUrl = event.target.src;
  }

}
