import { Component, OnInit } from '@angular/core';
import * as menuData from './../about/about.json';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  collections: Collection[] = (menuData as any).default;;
  aboutDesigner = "About Designer";
  fashionWeekHeader = "Fashion Week - Participations";
  collectionHeader = "Collection";
  yearHeader = "Year";
  about1 = "Born in Romania in a Greek-Armenian family where art, design and exact digits prevail, the designer has learned from a young age a little bit about everything, combining later the art, the fashion creation with the accuracy of the curved lines of the modern woman full of seduction and sensuality.";
  about2 = "The designer was a quick learner, she rapidly gain the necessary skills not only for fashion design and the techniques of clothing construction and manufacture. Apart of Fashion Design studies, she studied Management at the faculty of Economics  Sciences and has a master degree in Science in Investment Management.";
  about3a = "Herminas Reea has been present to the most important fashion weeks in U.S.A, Greece, Malta & Romania. </br> <a class=\"menufocus\" href=\"https://www.MiamiFashionWeek.com\" target=\"_blank\"> Miami Fashion Week</a> IS THE SECOND MOST RELEVANT FASHION EVENT IN THE USA\" (Source - Nielsen, March 2018)";
  about3b = "These unique luxurious pieces can be delivered worldwide. Herminas Reea Brand is about luxury, confidence and empowerment to women.";
  about3c = "Herminas Reea is a Luxury Fashion Designer label specialized in designing and creating unique ultra-feminine evening gowns, bridal gowns and Haute Couture dresses. We are creating fashion as a piece of art with fabric, an art to be worn and the body becomes it's canvas. It's about the quickest route to feeling like everything is new again, that everything is possible. The combination of colors, details and materials - all together bring flair, drama and a refined sensuality simultaneously to all the dresses.";
  about3d = "<a href=\"https://www.w3schools.com\" target=\"_blank\"> Miami Fashion Week</a> \"IS THE SECOND MOST RELEVANT FASHION EVENT IN THE USA\" (Source - Nielsen, March 2018)";
  about4logo = "Herminas Reea";
  about4a = "Romanian Fashion Philosofy 2017";
  about4b = "Mercedes Benz Malta Fashion Week 2017";
  about4c = "Athens Xclusive Designer Week 2018 (AXDW23)";
  about4d = "Mercedes Benz Malta Fashion Week 2018";
  about4e = "MIAMI FASHION WEEK 2019";
  about4f = "Athens Xclusive Designer Week 2019 (AXDW25)";
  about5 = "Herminas Reea has appeared in fashion magazines such as Vogue UK, Vogue Italy Online, MAN Magazine â€“ Iceland or Mada Lâ€™Officiel â€“ Lithuania."
  about6 = "<span class=\"logo1\"> Herminas Reea</span> <span class=\"typed\"> represented Greece in Malta Fashion Week 2017 & Malta Fashion Week 2018.</span>";
  videoId: string;
  urlSafe: SafeResourceUrl;
  modalOption: NgbModalOptions = {};
  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) { 
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute("style", "background-color: #222222");
  }

  ngOnInit() {
  }

  /**
 * Function purpose: open modal
 * @param content
 */
  openModal(content) {
    //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    this.modalOption.centered=true;
    if(this.videoId.length > 1)
      this.modalService.open(content, this.modalOption);
  }

  /**
   * Function purpose : set image url
   * @param event 
   */
  onClick(event) {
    this.videoId = event;
  }

}

export class Collection {
  name: string;
  year: number;
  fashionWeek: string;
  videoUrl: string;

}
