import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as imagesloaded from 'imagesloaded';
import { animate, style } from '@angular/animations';
import { NgxMasonryAnimations, NgxMasonryOptions } from 'ngx-masonry';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { IAlbum, Lightbox } from 'ngx-lightbox';
 
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit {

  press = [
    "images/press/marquis.jpg",
    "images/press/marquis1.jpg",
    "images/press/marquis2.jpg",
    "images/press/marquis3.jpg",
    "images/press/marquis4.jpg",
    "images/press/marquis5.jpg",
    "images/press/2019/a1.jpg",
    "images/press/2019/a2.jpg",
    "images/press/2019/uguj.jpg",
    "images/press/2019/fvt.jpg",
    "images/press/2019/m2019_7.jpg",
    "images/press/2019/m2019_8.png",
    "images/press/2019/m2019_9.png",
    "images/press/2019/m2019_10.png",
    "images/press/2019/m2019_12.png",
    "images/press/2019/m2019_15.png",
    "images/press/2019/m2019_16.png",
    "images/press/2019/m2019_17.png",
    "images/press/2019/m2019_20.jpg",
    "images/press/2019/m2019_2.jpg",
    "images/press/2019/m2019_3.jpg",
    "images/press/2019/m2019_4.jpg",
    "images/press/2019/m2019_5.jpg",
    "images/press/2019/m2019_6.jpg",
    "images/press/2019/m2019_11.jpg",
    "images/press/2019/m2019_13.JPG",
    "images/press/2019/m2019_14.JPG",
    "images/press/2019/m2019_18.png",
    "images/press/2019/m2019_19.png",
    "images/press/vi181.jpg",
    "images/press/vi185.jpg",
    "images/press/vi183.jpg",
    "images/press/vi184.jpg",
    "images/press/vi182.jpg",
    "images/press/md.jpg",
    "images/press/VisitMeMalta.jpg",
    "images/press/vida1.jpg",
    "images/press/vida2.jpg",
    "images/press/articol1.png",
    "images/press/articol2.png",
    "images/press/articol3.png",
    "images/press/articol4.png",
    "images/press/bf1.png",
    "images/press/bf2.jpg",
    "images/press/bf3.jpg",
    "images/press/bf4.png",
    "images/press/fabuk.png",
    "images/press/vogspt.JPG",
    "images/press/lofficial.jpg",
    "images/press/man1.jpg",
    "images/press/man2.jpg",
    "images/press/man3.jpg",
    "images/press/img9.jpg",
    "images/press/anna_VogueIt_1.JPG",
    "images/press/anna_VogueIt_2.JPG",
    "images/press/anna_VogueIt_3.JPG",
    "images/press/VogueIt.jpg",
    "images/press/img8.jpg",
    "images/press/img1.png",
    "images/press/img2.jpg",
    "images/press/img3.jpg",
    "images/press/img4.jpg",
    "images/press/img5.jpg",
    "images/press/img6.jpg",
    "images/press/img7.jpg",
    "images/press/kamile1.jpg",
    "images/press/kamile2.jpg",
    "images/press/1.jpg",
    "images/press/2.jpg",
    "images/press/3.jpg",
    "images/press/3.jpg",
    "images/press/ellas.jpg"
  ];

  photo = [
    "images/annaosk/dahe19.jpg",
    "images/annaosk/dahe2.jpg",
    "images/annaosk/dahe13.jpg",
    "images/annaosk/dahe4.jpg",
    "images/annaosk/dahe12.jpg",
    "images/annaosk/dahe6.jpg",
    "images/annaosk/dahe7.jpg",
    "images/annaosk/dahe8.jpg",
    "images/annaosk/dahe3.jpg",
    "images/annaosk/dahe10.jpg",
    "images/annaosk/dahe11.jpg",
    "images/annaosk/dahe5.jpg",
    "images/annaosk/dahe9.jpg",
    "images/annaosk/dahe14.jpg",
    "images/annaosk/dahe15.jpg",
    "images/annaosk/dahe16.jpg",
    "images/annaosk/dahe17.jpg",
    "images/annaosk/dahe18.jpg",
    "images/annaosk/dahe1.jpg",
    "images/annaosk/dahe20.jpg",
    "images/annaosk/dahe21.jpg",
    "images/annaosk/dahe22.jpg",
    "images/annaosk/dahe23.jpg",
    "images/annaosk/dahe24.jpg",
    "images/annaosk/dahe25.jpg",
    "images/annaosk/dahe26.jpg",
    "images/annaosk/andreea1.jpg",
    "images/annaosk/andreea2.jpg",
    "images/annaosk/andreea4.jpg",
    "images/annaosk/romea6.jpg",
    "images/annaosk/romea7.jpg",
    "images/annaosk/romea8.jpg",
    "images/annaosk/romea9.jpg",
    "images/annaosk/romea10.jpg",
    "images/annaosk/romea11.jpg",
    "images/annaosk/romea12.jpg",
    "images/annaosk/romea13.jpg",
    "images/annaosk/romea14.jpg",
    "images/annaosk/romea15.jpg",
    "images/annaosk/romea16.jpg",
    "images/annaosk/romea17.jpg",
    "images/annaosk/romea18.jpg",
    "images/annaosk/romea19.jpg",
    "images/annaosk/romea20.jpg",
    "images/annaosk/romea21.jpg",
    "images/annaosk/romea22.jpg"
  ];

  style = [
    "images/press/2019/b1.jpg",
    "images/press/2019/b2.jpg",
    "images/style/iroaxdw25.jpg",
    "images/style/a5.jpg",
    "images/style/a2.jpg",
    "images/style/a3.jpg",
    "images/style/a4.jpg",
    "images/style/a6.jpg",
    "images/style/a9.jpg",
    "images/style/a7.jpg",
    "images/style/a8.jpg",
    "images/style/a1.jpg",
    "images/style/a10.jpg",
    "images/style/1.jpg",
    "images/style/2.jpg",
    "images/style/3.jpg",
    "images/style/4.jpg",
    "images/style/5.jpg",
    "images/style/6.jpg",
    "images/style/7.jpg",
    "images/style/8.jpg",
    "images/style/9.jpg",
    "images/style/10.jpg",
    "images/style/11.jpg",
    "images/style/12.jpg",
    "images/style/13.jpg",
    "images/style/14.jpg",
    "images/style/15.jpg",
    "images/style/16.jpg",
    "images/style/17.jpg",
    "images/style/18.jpg",
    "images/style/19.jpg",
    "images/style/20.jpg",
    "images/style/21.jpg",
    "images/style/22.jpg",
    "images/style/23.jpg",
    "images/style/24.jpg"
  ];

  video = [
    "https://www.youtube-nocookie.com/embed/M2BqUrPCKzs",
    "https://www.youtube.com/embed/Kk3xpdBAoBo",
    "https://www.youtube.com/embed/fxxphoNd26A",
    "https://www.youtube.com/embed/uK15_xBeO4Y",
    "https://www.youtube.com/embed/7tm9wvKBOO4",
    "https://www.youtube.com/embed/cFTlsndhEhA",
    "https://www.youtube.com/embed/1D77MRBkrlo",
    "https://www.youtube.com/embed/Sr4WYs5cM-Q",
    "https://ytcropper.com/cropped/cF5c02b4114a092",
    "https://www.youtube.com/embed/EIRX20tAJAM"
  ];

  videoImg = [
    "images/vsmall/miami.JPG",
    "images/vsmall/1.PNG",
    "images/vsmall/2.PNG",
    "images/vsmall/3.PNG",
    "images/vsmall/4.PNG",
    "images/vsmall/5.PNG",
    "images/vsmall/6.PNG",
    "images/vsmall/7.PNG",
    "images/vsmall/8.PNG",
    "images/vsmall/9.PNG"
  ];

  videoImgHover = [
    "images/vsmall/miami1.JPG",
    "images/vsmall/1a.PNG",
    "images/vsmall/2a.PNG",
    "images/vsmall/3a.PNG",
    "images/vsmall/4a.PNG",
    "images/vsmall/5a.PNG",
    "images/vsmall/6a.PNG",
    "images/vsmall/7a.PNG",
    "images/vsmall/8a.PNG",
    "images/vsmall/9a.PNG"
  ];

  anim:NgxMasonryAnimations = {
    show: [
      style({opacity: 0}),
      animate('400ms ease-in', style({opacity: 1})),
    ],
    hide: [
      style({opacity: '*'}),
      animate('400ms ease-in', style({opacity: 0})),
    ]
  }

  gridOptions: NgxMasonryOptions = {
    horizontalOrder: true,
    originLeft: true,
   /* animations: this.anim,
    fitWidth: true,*/
    resize: true
  };

  photoTmp1=[];
  photoTmp2=[];
  currentView:String='';

  currentImageArray: String[] = [];
  urlSafe: SafeResourceUrl;
  imgUrl: String;
  modalOption: NgbModalOptions = {};
  private images: Array<IAlbum> = [];

  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer, private route: ActivatedRoute, private lightbox: Lightbox) {
    this.route.params.subscribe(params => this.updateSliderView(params.section));
  }

  ngOnInit(): void {
  }

  open(index: number): void {
    if(!this.isVideo()){
      this.images=[];
      for (let i = 0; i <= this.currentImageArray.length; i++) {
        const src = this.currentImageArray[i];
        let image:IAlbum={
          src: "assets/"+src,
          caption: '',
          thumb: ''
        }
        this.images.push(image);
      }
      // open lightbox
      this.lightbox.open(this.images, index);
    }
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  /**
   * Function purpose: populate current image array
   * @param gal : current 
   */
  updateSliderView(gal: string) {
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute("style", "background-color:#ffffff");
    this.currentImageArray = [];
    console.log("updateSliderView clicked -> " + gal);
    this.currentView='';

    switch (gal) {
      case 'media': {
        this.currentImageArray = this.press;
        break;
      }
      case 'look': {
        this.currentImageArray = this.style;
        break;
      }
      case 'video': {
        this.currentView='video';
        this.currentImageArray = this.videoImg;
        break;
      }
      case 'photo': {
        this.currentImageArray = this.photo;
        break;
      }
      default: {
        break;
      }
    }

  }

  /**
  * Function purpose: open modal
  * @param content
  */
  openModal(content) {
    console.log("content-> " + content);
    let videoUrl:string='';
    if(this.isVideo()){
      console.log("video true");
      console.log("pic url : "+this.imgUrl);
      let indexOfVideo:number = this.currentImageArray.indexOf(this.imgUrl.substring(this.imgUrl.indexOf('images')));
      console.log("index of : "+indexOfVideo);
      videoUrl = this.video[indexOfVideo];
      console.log("video url : "+videoUrl);
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
      this.modalOption.backdrop = 'static';
      this.modalOption.keyboard = false;
      this.modalOption.size = 'lg';
      this.modalOption.centered=true;
      this.modalService.open(content, this.modalOption);
    }
    /*this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    this.modalOption.centered=true,
    this.modalService.open(content, this.modalOption);*/
  }

  /**
   * Function purpose : set image url
   * @param event 
   */
  setUrl(event) {
    this.imgUrl = event.target.src;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.resize(this.currentImageArray,window.innerWidth);
    //console.log("resize->"+this.currentImageArray+" x "+window.innerWidth);
  }

  resize(currentImageArray:String[],width:Number){
    if(width > 1023){
      let x:String[]=[];
      let y:String[]=[];
      let z:String[]=[];
      let i:number = 0; 
      let j:number = 0; 
      for(i;i<currentImageArray.length;){
        x[j]=currentImageArray[i++];
        y[j]=currentImageArray[i++];
        z[j]=currentImageArray[i++];
        j++;
      }
      currentImageArray=[];
      currentImageArray=currentImageArray.concat(x);
      currentImageArray=currentImageArray.concat(y);
      currentImageArray=currentImageArray.concat(z);
      this.currentImageArray=[];
      this.currentImageArray=this.currentImageArray.concat(currentImageArray);

    }else if(width > 768){

    }
  }

  isVideo():boolean{
    if(this.currentView === 'video'){
      return true;
    }else{
      return false;
    }
  }
}

class Point {
  x: number;
  y: number;
}