<!-- Contact-->
<div id="contactview" class="container main contactview">
	<div class="jumbotron jumbotron-fluid" style="background-color:#222222">
	    <div class="main text-white">
            <div class="row">
                <div class="col-12">
                    <p class="text-justify" style="padding-top:70px;">
                        <code class="logo1">Herminas Reea</code> is based in Bucharest, Romania. All dresses are designed and hand crafted, using the finest fabrics and laces. The design, construction and fitting process can take few weeks.
                        The showroom is located in the heart of Bucharest, in the neighborhood Cotroceni, for appointment or any questions please contact us in private.</p>
                    <br>
                </div>
            </div>
	
            <div class="row"> 
                <div class="col-12 col-md-6">
                    <div class="row">

                        <div class="col-12">
                            <h2 class="text-uppercase mt-3 font-weight-bold text-white">CONTACT DETAILS</h2>
                            <p class="text-justify">Should you wish to contact us with any queries or you would like to obtain further information on our collections, please fill out the electronic form on the right. Alternatively, you can contact us on one of the below details.</p>
                            <p><span class="fas fa-phone" style="font-size:16px;color:#D214AF;padding:0 0"></span> (+40)722197368</p>
                            <p><span class="far fa-envelope" style="font-size:16px;color:#D214AF"></span>  herminasreea@gmail.com</p>  
                        </div>
                        
                        <div class="col-12">
                            <!-- Add font awesome icons -->
                            <a target="_blank" href="https://www.facebook.com/HerminasBoutique" class="fa fa-facebook"></a>
                            <a target="_blank" href="https://www.instagram.com/herminasreea" class="fa fa-instagram"></a>
                            <!-- <a target="_blank" href="javascript:void(0)" class="fa fa-google"></a>-->
                            <a target="_blank" href="https://www.youtube.com/channel/UCulJ0Ljx8__MjZGzYFsE9ww" class="fa fa-youtube"></a>
                            <!-- <a target="_blank" href="javascript:void(0)" class="fa fa-pinterest"></a>-->
                        </div>
                    </div>
                </div>
            
                <div class="col-12 col-md-6">
                <h2 class="text-uppercase mt-3 font-weight-bold text-white">SEND A MESSAGE</h2>
                    <form action="">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control mt-2" placeholder="Your Name" required>
                                </div>
                            </div>
                    
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="email" class="form-control mt-2" placeholder="Your e-mail" required>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Subject" rows="1" required></textarea>
                                </div>
                            </div>
                    
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Message" rows="5" required></textarea>
                                </div>
                            </div>
                            <!--<div class="col-12">
                                <button class="btn btn-light disabled" type="submit">Send</button>
                            </div>-->
                        </div>
                    </form>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<footer class="footer1 py-5 footer">
    <div class="container">
      <p class="m-0 text-center text-white">Copyright &copy; Herminas Reea 2022</p>
    </div>
</footer>
