<br>
<div class="container masonry" style="background-color: #ffffff;">
  <!-- start masonry-->
  <!--<div class="masonry-wrapper">
    <div class="masonry">
      <div class="masonry-item" *ngFor="let image of currentImageArray">
        <img [src]="'assets/'+image" alt="Dummy Image" class="masonry-content" (click)="openModal(content);setUrl($event)">
      </div>
    </div>
  </div>-->
 <!--start tmp-->
    <ngx-masonry [options]="gridOptions">
        <div ngxMasonryItem class="masonry-item" *ngFor="let image of currentImageArray; let i=index">
          <!--<img [src]="'assets/'+image" alt="Fashion Image" class="masonry-content" (click)="setUrl($event);openModal(content);" />-->
          <img [src]="'assets/'+image" alt="Fashion Image" class="masonry-content" (click)="setUrl($event);open(i);openModal(content);" />
        </div>
    </ngx-masonry>

 <!--stop tmp-->

  <!--end of file-->
</div>

<ng-template #content let-modal>
    <div style="position: relative;">
        <!--Close button-->
      <div style="position: absolute; top: 10px; right: 10px;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--content-->
      <img id="imgModal" *ngIf="!isVideo()" [src]="imgUrl" class="img-fluid" alt="Responsive image">
      <div *ngIf="isVideo()" class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]="urlSafe" id="videoShow"  allowscriptaccess="always"></iframe>
      </div> 
      <!--footer-->
        <div class="modal-footer">
          <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        </div>
    </div>
</ng-template>