import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { SliderComponent } from './slider/slider.component';
import { GridComponent } from './grid/grid.component';
import { ShopComponent } from './shop/shop.component';
import { RedirectGuard } from './shop/RedirectGuard';


const routes: Routes = [

  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'slide/:collection',
    component: SliderComponent
  },
  {
    path: 'grid/:section',
    component: GridComponent
  },
  {
    path: 'shop',
    canActivate: [RedirectGuard],
     component: RedirectGuard,
     data: {
       externalUrl: 'https://shop.herminasreea.com/'
     }
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: '',
    component: SliderComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
