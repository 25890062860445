<swiper [config]="config1">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let image of images">
        <img class="himg img-thumbnail"  (click)="openModal(content);setUrl($event)" [src]="'assets/'+image" alt="Images collection" />
      </div>
    </div>
  
    <div class="swiper-button-next1"></div>
    <div class="swiper-button-prev1"></div>
</swiper>

<ng-template #content let-modal>
    <div style="position: relative;">
        <!--Close button-->
      <div style="position: absolute; top: 10px; right: 10px;">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--content-->
      <img id="imgModal" *ngIf="true" [src]="imgUrl" class="img-fluid" alt="Responsive image">
      <div *ngIf="false" class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]="urlSafe" id="videoShow"  allowscriptaccess="always"></iframe>
      </div> 
      <!--footer-->
        <div class="modal-footer">
          <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        </div>
    </div>
</ng-template>
