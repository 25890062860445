import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor() {
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute("style", "background-color: #222222");
   }

  ngOnInit(): void {
    
  }

}
