import * as data from './../slider/slider.json';

export class ImageUtil{
    imagesData: {'name':string,'images':Images[]}[] = (data as any).default;
    theGoods : ImagesData=this.imagesData[0];
    theGoods_Buc : ImagesData=this.imagesData[1];
    theGoods_Malta : ImagesData=this.imagesData[2];
    butterflyEffect : ImagesData=this.imagesData[3];
    butterflyEffect_AXDW : ImagesData=this.imagesData[4];
    butterflyEffect_Malta : ImagesData=this.imagesData[5];
    theBirds : ImagesData=this.imagesData[8];
    theBirds_MIAFW : ImagesData=this.imagesData[6];
    theBirds_AXDW : ImagesData=this.imagesData[7];
    bridal : ImagesData=this.imagesData[9];
    men_shirt : ImagesData=this.imagesData[10];
    men_coats : ImagesData=this.imagesData[11];
    bjeans : ImagesData=this.imagesData[12];
    men_long_coats : ImagesData=this.imagesData[13];
    partners : ImagesData=this.imagesData[14];
    kids : ImagesData=this.imagesData[15];
    bsuits : ImagesData=this.imagesData[16];
    initSlider : ImagesData=this.imagesData[17];
    lostInTheCity_CRFW : ImagesData=this.imagesData[18];
    lostInTheCity : ImagesData=this.imagesData[19];

    constructor(){}

}

export class Images {
    url:string;
    simple:boolean;
    slides:number;
}

export class ImagesData{
    name:string;
    images:Images[];
}