
    <nav class="navbar navbar-expand-xl navbar-dark contentMenu fixed-top">
      <a class="navbar-brand logo" href="http://www.herminasreea.com">
        Herminas Reea
      </a>
      
      <button [attr.aria-expanded]="!isCollapsed" class="navbar-toggler" type="button" 
      aria-controls="navbarResponsive" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="collapse navbar-collapse " [ngbCollapse]="!isCollapsed">
        <ul class="navbar-nav ml-auto">
          <!-- Menus-->
          <div *ngFor="let menuItem of menuItems"> 
            <li *ngIf="menuItem.isSimple" class="nav-item myClass">
              <a class="nav-link simpleItem" href="javascript:void(0)" [routerLink]="menuItem.routeLink" routerLinkActive="active" (click)="isCollapsed = false" skipLocationChange> {{menuItem.name}} </a>
            </li>
            <li ngbDropdown *ngIf="!menuItem.isSimple" class="nav-item dropdown">
              <a ngbDropdownToggle class="nav-link" href="javascript:void(0)" skipLocationChange>{{menuItem.name}}</a>
              <div ngbDropdownMenu class="dropdown-menu">
                  <a ngbDropdownItem *ngFor="let subItem of menuItem.subItems" href="javascript:void(0)" class="dropdown-item slidePanel menua" [ngClass]="{'menufocus':subItem.active}" [routerLink]="subItem.routeLink" (click)="isCollapsed = false" skipLocationChange>{{subItem.name}}</a>
              </div>
            </li>
          </div> 
        </ul>
    </div>
  </nav>
