<br>
<!-- Desktop carousel -->
<swiper [config]="config" *ngIf="scrWidth > 768" #usefulSwiper>
    <div id="inner" class="swiper-wrapper inner">
      <div id="carousel" class="swiper-slide" *ngFor="let slide of slides1">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm"><img class="slideImg" [src]="'assets/'+slide.img1" alt="Image 1"></div>
            <div class="col-sm" *ngIf="slide.img2 != null"><img class="slideImg" [src]="'assets/'+slide.img2" alt="Image 2"></div>
            <div class="col-sm" *ngIf="slide.img3 != null"><img class="slideImg" [src]="'assets/'+slide.img3" alt="Image 3"></div>
            <div class="col-sm" *ngIf="slide.img4 != null"><img class="slideImg" [src]="'assets/'+slide.img4" alt="Image 4"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination pagina-0"></div>
    <div class="swiper-button-next btn-next-0"></div>
    <div class="swiper-button-prev btn-prev-0"></div>
    
  </swiper>

<!-- Mobile carousel -->
<section class="content py-5" *ngIf="scrWidth < 768">
  <div id="carousel" class="swiper-slide" *ngFor="let slide of currentImageArray.images">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm"><img class="slideImg hmobl" [src]="'assets/'+slide.url" alt="Image"></div>
      </div>  
    </div>
  </div>
</section>

<!-- Collection carousel -->
<section id="sliderContent" class="content py-5">
  <div class="container">
    <h1 class="htitle">"The Birds" collection</h1>
    <p style="font-size: 14px;">"The Birds"  collection was showcased during 
      <a class="fwlink" href="https://miamifashionweek.com/" target="_blank" matTooltip="“MIAFW IS THE SECOND MOST RELEVANT FASHION EVENT IN THE USA” (Source - Nielsen, March 2018)"> Miami Fashion Week 2019 </a>  & 
      <a class="fwlink" href="https://www.axdw.gr/" target="_blank" matTooltip="AXDW is the international fashion week of Athens, Greece , having realized 24 editions since 2007."> Athens Xclusive Designers Week (AXDW25)</a>
    </p>
    <br>
    <div id="row">
      <app-smallslider></app-smallslider>
    </div>
 </div>
</section>

<!-- Footer -->
<footer class="footer1 py-5 footer">
  <div class="container">
    <p class="m-0 text-center text-white">Copyright &copy; Herminas Reea 2022</p>
  </div>
</footer>